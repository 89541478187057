/* eslint-disable */

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { transformProxies } from "./helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63553f7f4f3fdf4d450fd417"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class ProduktyView extends React.Component {
  constructor() {
    super();
    this.state = {
      accordionOpenIndex: null,
      lightbox: null,
    };
  }
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("..controllers/CenikController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = ProduktyView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "637042ef4679d85524409e3c";
    htmlEl.dataset["wfSite"] = "63553f7f4f3fdf4d450fd417";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      ProduktyView.Controller !== ProduktyView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <Helmet>
          <title>Produkty a funkce | Jeden systém pro všechno! | RESTIA</title>
          <meta
            name="description"
            content="RESTIA poskytuje 50+ funkcí, které vám usnadní chod vaší restaurace. Eshop restaurace ✔️ online objednavky na vašem webu ✔️ dispečink pro řízení rozvozu ✔️ aplikace pro kurýry ✔️ jeden tablet pro všechny platformy ✔️ menu manager ✔️ menu importer ✔️ šetří náklady ✔️ telefonní objednávky ✔️"
          />
          <meta
            name="keywords"
            content="RESTIA, objednávkový systém, eshop pro restaurace, aplikace pro řidiče, dispečink, menu manager, menu import, všechny objednávky na jednom místě, integrace s POS, wolt, bolt, foodora, damejidlo"
          />
        </Helmet>
        <style
        //   dangerouslySetInnerHTML={{
        //     __html: `
        //   @import url(C:\\css\\normalize.css);
        //   @import url(C:\\css\\webflow.css);
        //   @import url(C:\\css\\restia.webflow.css);
        // `,
        //   }}
        />
        <span className="af-view">
          <div>
            <img
              src="images/path4722.svg"
              alt="background"
              title=""
              loading="lazy"
              className="header-pic"
            />
            <Header title={"Produkty"} selectedPage={"produkty"} />
            <section
              className="hero-heading-left wf-section"
              id="online-objednavky"
            >
              <div className="container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">RESTIA</h2>
                    <p className="margin-bottom-24px">
                      Intuitivní systém pro správu všech vašich online
                      objednávek s možností napojení na rozvoz. RESTIA vám
                      pomůže s digitálizací vaší restaurace pro
                      21.&nbsp;století, kde vám bude stačit jedna aplikace pro
                      kompletní chod restaurace.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/restia.webp"
                      loading="lazy"
                      alt="restia logo"
                      title="RESTIA"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            RESTIA Lite
                          </h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            RESTIA Pro
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 0 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 0 ? null : 0,
                        })
                      }
                    >
                      <div
                        data-w-id="83f2d679-7cf7-cb4f-0fca-91285f06bb61"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Propojení s POS
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Přijatou objednávku z online platformy už není nutné
                            ručně markovat. Je automaticky převedena do pokladny
                            a tím odpadá nejen zdlouhavé markování, ale i
                            případná chyba, která se v té rychlosti může stát.
                            Samozřejmostí jsou veškeré údaje z objednávky na
                            účtence a bonu pro kuchaře, obsluhu i kurýra.
                          </p>
                          <a
                            href="images/propojeni_s_POS.webp"
                            data-lightbox="screenshots"
                            data-title="Propojení s POS"
                          >
                            <img
                              src="images/propojeni_s_POS.webp"
                              loading="lazy"
                              alt="propojení s pokladním systémem POS"
                              title="Propojeno s pokladním systémem POS"
                              className="faq-answer-image"
                            />
                          </a>
                          {/* <img
														src="images/propojeni_s_POS.webp"
														loading="lazy"
														srcSet="images/online_objednavky2x-p-500.webp 500w, images/online_objednavky2x.webp 794w"
														sizes="(max-width: 479px) 100vw, (max-width: 767px) 231px, 211.15625px"
														alt
														className="faq-answer-image"
													/> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 1 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 1 ? null : 1,
                        })
                      }
                    >
                      <div
                        data-w-id="a81da464-d462-d056-e326-150fd98d4503"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">Menu Manager</div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Jedním klikem nahrajete Vaše menu do
                            Foodory(Dámejídla), Wolt, Bolt a dokonce i na Váš
                            web. Pokročilý Menu manager provede všechny změny do
                            30 vteřin a Vy budete mít jistotu, že zákazník vidí
                            vždy aktuální podobu Vašeho menu. Ušetřený čas,
                            který byste věnovali úpravám na každé platformě,
                            investujte raději do řízení podniku. A pokud se
                            bojíte, že úpravu menu včetně přísad nezvládnete,
                            tak věřte, že je to jednodušší než poslat email.
                          </p>
                          <a
                            href="images/menu_manager.webp"
                            data-lightbox="screenshots"
                            data-title="Menu Manager"
                          >
                            <img
                              src="images/menu_manager.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="menu manager menu importér správa menu"
                              title="Menu manager"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 2 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 2 ? null : 2,
                        })
                      }
                    >
                      <div
                        data-w-id="92947e65-c5bb-f9ad-66a7-9f3a500f43fc"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Napojení online profilů
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>1</div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>neome&shy;zeně</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Všechny objednávky z Foodory(Dámejídla), Woltu,
                            Boltu a Vašich webových stránek Vám ukážeme na
                            jednom místě – jednoduše, přehledně a se všemi
                            informacemi o objednávce. Již nebudete potřebovat
                            mít tablet pro každou platformu a také hned uvidíte
                            informace o kurýrovi, který k vám právě jede nebo
                            rozváží objednávku. U nové objednávky si můžete
                            zadat vlastní čas přípravy, případně Vám náš autobot
                            čas doporučí podle aktuálního vytížení kuchyně.{" "}
                          </p>
                          <a
                            href="images/napojeni_online_profilu.webp"
                            data-lightbox="screenshots"
                            data-title="Napojení online profilů"
                          >
                            <img
                              src="images/napojeni_online_profilu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="všechny platformy na jednom místě bolt, wolt, foodora"
                              title="Všechny objednávky na jednom místě"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 3 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 3 ? null : 3,
                        })
                      }
                    >
                      <div
                        data-w-id="66202e47-30bc-eff6-4909-bd468ca14885"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Zapínání/vypínání položek
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Vyprodali jste nějaké jídlo, nebo Vám došla důležitá
                            surovina a potřebujete rychle upravit nabídku? Díky
                            našemu Menu manageru skryjete potřebné jídlo jedním
                            kliknutím a to i v případě, že ho máte v několika
                            kategoriích. Druhý den Vás také informujeme o
                            skrytém produktu a už se Vám nestane, že na něj
                            zapomenete a dalších 14 dnů se nebude prodávat.{" "}
                          </p>
                          <a
                            href="images/vypnuti_menu.webp"
                            data-lightbox="screenshots"
                            data-title="Zapínání/vypínání položek"
                          >
                            <img
                              src="images/vypnuti_menu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="skrytí a zobrazení položek na všech platformách"
                              title="Skrytí a zobrazení položek na všech platformách"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 4 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 4 ? null : 4,
                        })
                      }
                    >
                      <div
                        data-w-id="a8a92b1b-f9da-473c-5cbc-6438bac8b36f"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Zapínání/vypínání profilů
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Máte hodně objednávek a nestíháte je vyřizovat nebo
                            nestíhají vaši kurýři? V administraci jednoduše
                            pozastavíte profil, celou platformu nebo klidně
                            všechny platformy najednou. Při vypnutí je možné
                            nastavit jejich automatické spuštění po Vámi zvolené
                            době či je ručně spustit kdykoli.
                          </p>
                          <a
                            href="images/pozastaveni_profilu.webp"
                            data-lightbox="screenshots"
                            data-title="Zapínání/vypínání profilů"
                          >
                            <img
                              src="images/pozastaveni_profilu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="dočasné pozastavení přijímání objednávek z každe platformy"
                              title="Pozastavení všech profilů na jednom místě"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 5 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 5 ? null : 5,
                        })
                      }
                    >
                      <div
                        data-w-id="78236de8-9229-30c5-4b98-cbcb34808e03"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Automatické potvrzování objednávek
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Věnujte čas těm, kteří to ocení – Vašim zákazníkům a
                            nechte potvrzování objednávek na nás. Čas
                            potvrzování si nastavíte podle možností Vaší kuchyně
                            a o vše ostatní se postará náš Autobot. Větší
                            objednávky automaticky potvrdíme s delším časem
                            přípravy či si sami zvolíte, kolik minut chcete ke
                            každé objednávce přidat navíc.
                          </p>
                          <a
                            href="images/autobot.webp"
                            data-lightbox="screenshots"
                            data-title="Automatické potvrzování objednávek"
                          >
                            <img
                              src="images/autobot.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="autobot automatické potvrzení objednávek z platforem bolt, wolt, foodora"
                              title="Autobot - automatické potvrzení objednávek"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 6 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 6 ? null : 6,
                        })
                      }
                    >
                      <div
                        data-w-id="c350282e-6690-d04e-773f-206d80e5290c"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Základní statistiky
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Každodenní vyúčtování už nikdy nebude problém.
                            Přehledně Vám ukážeme, kolik jste vyřídili
                            objednávek, jakým způsobem platili zákazníci i to,
                            jakou částku jste vybrali na dopravě. Všechny
                            informace lze samozřejmě posílat i na email.
                          </p>
                          <a
                            href="images/statistiky.webp"
                            data-lightbox="screenshots"
                            data-title="Základní statistiky"
                          >
                            <img
                              src="images/statistiky.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="statistiky prodeje obratů a tržeb"
                              title="Statistika prodeje"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 7 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 7 ? null : 7,
                        })
                      }
                    >
                      <div
                        data-w-id="59958d76-e62f-2540-c8ec-5edfa86d9548"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Rozšířené statistiky a exporty
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            V dnešní težké době je nutné mít přehledné
                            statistiky pro správné řízení podniku. Ve verzi
                            RESTIA Pro jsme pro Vás připravili přehled o
                            výkonnosti kuchyně, efektivitě a zpoždění kurýrů,
                            ale i online dostupnosti všech Vašich profilů. Díky
                            tomu Vám už nikdy nebudou klesat tržby bez toho,
                            abyste neznali přesný důvod.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 8 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 8 ? null : 8,
                        })
                      }
                    >
                      <div
                        data-w-id="50330549-e16e-3a53-8ba4-9732293de8dc"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Cena měsíčního paušálu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong>800 Kč</strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>1 800 Kč</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <section className="hero-heading-left wf-section" id="dispecink">
              <div className="container faq-container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">Dispečink</h2>
                    <p className="margin-bottom-24px">
                      Dispečink slouží pro řízení rozvozu Vašich objednávek a
                      plánování tras kurýrů. S řízeným dispečinkem budete řídit
                      rozvoz efektivně a tím doručíte daleko více objednávek v
                      kratším čase.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/dispecink.webp"
                      loading="lazy"
                      alt="dispečink pro rozvoz objednávek"
                      title="Dispečink"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container faq-container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            Neřízený
                          </h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading">
                            Řízený
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 9 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 9 ? null : 9,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081a8c"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Mobilní aplikace pro kurýry
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Díky jednoduché mobilní aplikaci pro Android i iOS
                            ušetříme čas kurýrům a oni tak zvládnout doručit
                            více objednávek. V aplikaci mají veškeré potřebné
                            informace o objednávce, jedním kliknutím zavolají
                            zákazníkovi či si spustí svoji oblíbenou navigaci
                            Waze, Google nebo mapy.cz. Již žádné opisování údajů
                            z lístků.{" "}
                          </p>
                          <a
                            href="images/cargo1.webp"
                            data-lightbox="screenshots"
                            data-title="Mobilní aplikace pro kurýry"
                          >
                            <img
                              src="images/cargo1.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 10 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 10 ? null : 10,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081aae"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Plánování tras kurýrem
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value"></div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Nechte plánovat kurýry, kterou objednávku chtějí
                            vézt. Nová objednávka je vždy zobrazena každému
                            aktivnímu kurýrovi a po přijetí jedním z nich se
                            ostatním skryje. Jednoduché a efektivní řízení při
                            malém počtu kurýrů.
                          </p>
                          <a
                            href="images/cargo2.webp"
                            data-lightbox="screenshots"
                            data-title="Plánování tras kurýrem"
                          >
                            <img
                              src="images/cargo2.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="automatické plánování trasy kurýra"
                              title="Plánování tras kurýrem"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 11 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 11 ? null : 11,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081abf"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Plánování tras dispečerem na mapě
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Při větším počtu objednávek a kurýrů je efektivnější
                            využít dispečera, který bude rozdělovat objednávek.
                            Vidí nejen veškeré aktivní objednávky na přehledné
                            mapě, ale i polohu jednotlivých kurýrů. Je schopný
                            plánovat rozvoz tak, aby na sebe objednávky
                            navazovaly a kurýr neztrácel čas dlouhými přejezdy.
                            Tímto způsobem řízení nezpozdíte žádnou objednávku a
                            díky tomu se k Vám bude zákazník vždy rád vracet.
                          </p>
                          <a
                            href="images/planovani_tras.webp"
                            data-lightbox="screenshots"
                            data-title="Plánování tras dispečerem na mapě"
                          >
                            <img
                              src="images/planovani_tras.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="plánování trasy rozvozu"
                              title="Plánování trasy dispečerem pro kurýra"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 12 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 12 ? null : 12,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ad0"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Automatické plánování tras (připravujeme)
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <div>490 Kč/měsíc</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Využijte moderní způsob řízení kurýrů. Náš Autobot
                            spravedlivě rozdělí objednávky mezi kurýry a najde
                            optimální cestu podle zvolených parametrů –
                            nejkratší cesta mezi objednávkami či nejrychlejší
                            doručení zákazníkovi.
                          </p>
                          <a
                            href="images/automaticke_planovani_tras.webp"
                            data-lightbox="screenshots"
                            data-title="Automatické plánování tras (připravujeme)"
                          >
                            <img
                              src="images/automaticke_planovani_tras.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="automatické plánování trasy rozvozu"
                              title="Automatické plánování tras"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 13 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 13 ? null : 13,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Počítání dojezdových časů k zákazníkovi
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Již se nemusíte bát telefonátů od zákazníka s
                            dotazem, kdy bude doručena jeho objednávka. S naším
                            přehledem víte, kde přesně se kurýr vezoucí
                            dotazovanou objednávku nachází a kdy ji bude
                            doručovat. Navíc také máte přehled o jeho výkonnosti
                            či zpoždění na každé objednávce. Čas doručení se
                            průběžně aktualizuje každou minutu.{" "}
                          </p>
                          <a
                            href="images/pocitani_dojezdovych_casu.webp"
                            data-lightbox="screenshots"
                            data-title="Počítání dojezdových časů k zákazníkovi"
                          >
                            <img
                              src="images/pocitani_dojezdovych_casu.webp"
                              loading="lazy"
                              className="faq-answer-image"
                              alt="výpočet doby rozvozu kurýra a jeho návratu"
                              title="Počítání dojezdových časů k zákazníkovi"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 14 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 14 ? null : 14,
                        })
                      }
                    ></div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 15 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 15 ? null : 15,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b25"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Statistiky rozvozu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            V statistikách rozvozu přehledně uvidíte výkonnost
                            jednotlivých kurýrů a důvody zpoždění objednávek.
                            Pomocí těchto statistik dokážete lépe řídit náklady,
                            kvalitu a odměňování kurýrů.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 16 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 16 ? null : 16,
                        })
                      }
                    >
                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b25"
                        className="faq-question"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Kniha jízd a vyúčtování kurýrů
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-cross.webp"
                              loading="lazy"
                              alt="nedostupné v tarifním plánu"
                              title="nedostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      >
                        <div className="faq-answer">
                          <p className="faq-answer-text">
                            Otevřete si knihu jízd a prohlédněte si statistiky
                            každého kurýra. Najdete zde všechny jeho trasy,
                            objednávky, denní nájezd kilometrů či průměrný počet
                            objednávek na trasu. Statistiky jsou dostupné i pro
                            kurýry v mobilní aplikaci, aby přesně věděli, jakou
                            tržbu mají odevzdat na konci směny.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`faq-accordion ${
                        this.state.accordionOpenIndex === 17 ? "open" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          accordionOpenIndex:
                            this.state.accordionOpenIndex === 17 ? null : 17,
                        })
                      }
                    >
                      <div
                        className={`faq-accordion ${
                          this.state.accordionOpenIndex === 18 ? "open" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            accordionOpenIndex:
                              this.state.accordionOpenIndex === 18 ? null : 18,
                          })
                        }
                      >
                        <div
                          data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                          className="faq-question faq-question-disabled"
                        >
                          <div className="faq-question-left">
                            <div className="faq-question-arrow-wrapper">
                              <div className="faq-question-arrow w-embed"></div>
                            </div>
                            <div className="faq-question-text">
                              Počet kurýrů v ceně
                            </div>
                          </div>
                          <div className="faq-question-right">
                            <div className="faq-question-right-value">3</div>
                            <div className="faq-question-right-value">3</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`faq-accordion ${
                          this.state.accordionOpenIndex === 19 ? "open" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            accordionOpenIndex:
                              this.state.accordionOpenIndex === 19 ? null : 19,
                          })
                        }
                      >
                        <div
                          data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081ae1"
                          className="faq-question faq-question-disabled"
                        >
                          <div className="faq-question-left">
                            <div className="faq-question-arrow-wrapper">
                              <div className="faq-question-arrow w-embed"></div>
                            </div>
                            <div className="faq-question-text">
                              Každý další kurýr
                            </div>
                          </div>
                          <div className="faq-question-right">
                            <div className="faq-question-right-value">
                              <div>350 Kč/měsíc</div>
                            </div>
                            <div className="faq-question-right-value">
                              <div>750 Kč/měsíc</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        data-w-id="8cb43bbb-f6a1-361f-5986-009fbf081b36"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Cena měsíčního paušálu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong>1 500 Kč</strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>2 000 Kč</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <section
              className="hero-heading-left wf-section"
              id="vlastni-eshop"
            >
              <div className="container faq-container">
                <div className="hero-wrapper-3">
                  <div className="pricing-heading">
                    <h2 className="heading-2">Vlastní eshop</h2>
                    <p className="margin-bottom-24px">
                      Vlastní online objednávky pro vaše zákazníky, kde si
                      přizpůsobíte vzhled podle vašich přání a neplatí žádné
                      poplatky z objednávek. Možnost vložit libovolný obrázek
                      pro jídla a kategorie s možností přídavků k jídlům.
                    </p>
                  </div>
                  <div className="pricing-heading">
                    <img
                      src="images/web-restia.webp"
                      loading="lazy"
                      alt="vlastní eshop restaurace"
                      title="Vlastní eshop restaurace"
                      className="service-image"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="faq-section">
              <div className="container faq-container">
                <div className="faq">
                  <div className="faq-list">
                    <div className="faq-description">
                      <div className="faq-question-right">
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading"></h3>
                        </div>
                        <div className="faq-question-right-value">
                          <h3 className="heading-3 faq-question-right-value-heading"></h3>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66cf4"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            Realtime komunikace se zákazníkem
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <div>
                              <img
                                src="images/fa-check.webp"
                                loading="lazy"
                                alt="dostupné v tarifním plánu"
                                title="dostupná funkce"
                                className="faq-question-right-value-image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66d07"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            Online platební brána s Apple a Google pay
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <div>
                              <img
                                src="images/fa-check.webp"
                                loading="lazy"
                                alt="dostupné v tarifním plánu"
                                title="dostupná funkce"
                                className="faq-question-right-value-image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66caf"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            Bonusový program, akce a vouchery pro zákazníky
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66ce3"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            Vlastní vzhled stránek s možností obrázků pro jídla
                            a kategorie
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="3f7a7d9b-2637-b39a-3792-7d2ba7111281"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            Rozvozové zóny
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="3f7a7d9b-2637-b39a-3792-7d2ba7111281"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow w-embed"></div>
                          </div>
                          <div className="faq-question-text">
                            QR menu pro vaše zákazníky
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value"></div>
                          <div className="faq-question-right-value">
                            <img
                              src="images/fa-check.webp"
                              loading="lazy"
                              alt="dostupné v tarifním plánu"
                              title="dostupná funkce"
                              className="faq-question-right-value-image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="faq-accordion">
                      <div
                        data-w-id="f3077e64-102b-4e38-16e0-f96516c66d2b"
                        className="faq-question faq-question-disabled"
                      >
                        <div className="faq-question-left">
                          <div className="faq-question-arrow-wrapper">
                            <div className="faq-question-arrow faq-question-arrow-disabled w-embed">
                              <svg
                                width=" 100%"
                                height=" 100%"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5303 20.884C16.2374 21.1769 15.7625 21.1769 15.4696 20.884L7.82315 13.2375C7.53025 12.9446 7.53025 12.4698 7.82315 12.1769L8.1767 11.8233C8.46959 11.5304 8.94447 11.5304 9.23736 11.8233L15.9999 18.5859L22.7625 11.8233C23.0554 11.5304 23.5303 11.5304 23.8231 11.8233L24.1767 12.1769C24.4696 12.4698 24.4696 12.9446 24.1767 13.2375L16.5303 20.884Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="faq-question-text">
                            Cena měsíčního paušálu
                          </div>
                        </div>
                        <div className="faq-question-right">
                          <div className="faq-question-right-value">
                            <div>
                              <strong></strong>
                            </div>
                          </div>
                          <div className="faq-question-right-value">
                            <div>
                              <strong>900 Kč</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                        }}
                        className="faq-answer-wrapper"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <div className="extra-section wf-section">
              <div className="container extra-container w-container">
                <div className="extra">
                  <h2 className="heading-3">První spuštění</h2>
                  <div className="w-layout-grid grid-2">
                    <div
                      id="w-node-b9067f31-9156-b701-ba3b-45a66c638867-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div id="w-node-_4598aed1-de80-3deb-a433-72b6a41611e9-24409e3c">
                        Instalační fee a školení
                      </div>
                    </div>
                    <div
                      id="w-node-e53baccb-aad3-efb2-a30a-6bde6df2f581-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>2 490 Kč</div>
                    </div>
                    <div
                      id="w-node-eda47b89-afca-c196-06d4-bdebe9433f4b-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Napojení jednoho profilu</div>
                    </div>
                    <div
                      id="w-node-_5226ec36-fb86-8555-727d-59a714c9af70-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>1 000 Kč</div>
                    </div>
                    <div
                      id="w-node-_9556ba17-0b28-e0cb-cde9-b601395be1c9-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Tablet do provozovny</div>
                    </div>
                    <div
                      id="w-node-_8836d8c1-6b85-0e8c-14c2-e1d2df177d99-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>5&nbsp;000 Kč</div>
                    </div>
                  </div>
                </div>
                <div className="extra">
                  <h3 className="heading-3">Extra</h3>
                  <div className="w-layout-grid grid-2">
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c2-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c3-24409e3c">
                        Napojení Vašeho webu
                      </div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c5-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>5 000 Kč</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1c8-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Eshop RESTIA</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1cb-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>7 990 Kč</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1ce-24409e3c"
                      className="extra-grid-item extra-grid-item-left"
                    >
                      <div>Pronájem eshopu</div>
                    </div>
                    <div
                      id="w-node-_53e45305-c862-7e30-3058-fa7efac0e1d1-24409e3c"
                      className="extra-grid-item extra-grid-item-right"
                    >
                      <div>900 Kč/měsíc</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
								href="/#product-price"
								className="button button-centered w-inline-block">
								<img
									src="images/settings.svg"
									loading="lazy"
									alt="alt_images/settings.svg"
                  title="title_images/settings.svg"
									className="image-9"
								/>
								<div className="text-block-2">Konfigurovat</div>
							</a> */}
              <Link
                to="/#product-price"
                className="button button-centered w-inline-block"
              >
                <img
                  src="images/settings.svg"
                  loading="lazy"
                  alt="konfigurátor ceny produktu"
                  title="Konfigurátor ceny"
                  className="image-9"
                />
                <div className="text-block-2">Konfigurovat</div>
              </Link>
            </div>
            <Footer />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default ProduktyView;

/* eslint-enable */
