import React, { useRef, useState } from "react";
import {
  EMAILJS_CONTACT_TEMPLATE_ID,
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVICE_ID,
} from "../constants";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ContactFormProps {
  hasMarginTop: boolean;
}

const ContactForm = (props: ContactFormProps) => {
  const { hasMarginTop } = props;
  const [emailForm, setEmailForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const toastId = useRef<any>();

  const sendEmail = () => {
    const sendingToast = () =>
      (toastId.current = toast("Odesílání zprávy...", {
        autoClose: false,
      }));
    sendingToast();

    fetch("https://icrm.restia.cz/api/contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "02a232c550e87b1cc8c95b070d9f69a2",
      },
      body: JSON.stringify(emailForm),
    })
      .then((response) => {
        // handle response - just json { "message": "success.." }
      })
      .catch((error) => {
        // handle error - may be 401 Unauthorized
      });

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_CONTACT_TEMPLATE_ID,
        emailForm,
        EMAILJS_PUBLIC_KEY
      )
      .then((res) => {
        setEmailForm({
          name: "",
          email: "",
          message: "",
        });
        toast.dismiss(toastId.current);
        setTimeout(() => {
          toast.success(
            "Děkujeme za odeslání formuláře. Potvrzení najdete ve vašem zadaném emailu."
          );
        }, 300);
      })
      .catch((err) => {
        toast.dismiss(toastId.current);
        setTimeout(() => {
          toast.error("Něco se pokazilo. Zkuste to prosím znovu.");
        }, 300);
      });
  };

  return (
    <>
      <ToastContainer
        position="bottom-left"
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={true}
        theme="light"
      />
      <section
        className={`contact-section ${
          hasMarginTop ? "contact-section--separate" : ""
        } wf-section`}
      >
        <div className="container">
          <h2 className="heading-2">Nebojte se nám ozvat</h2>
          <div className="contact-wrapper">
            <div className="contact-column contact-column-left">
              <div className="form-block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="form"
                >
                  <input
                    type="text"
                    className="contact-form-field w-input"
                    maxLength={256}
                    name="Jm-no"
                    data-name="Jméno"
                    placeholder="Jméno"
                    id="jmeno"
                    required
                    value={emailForm.name}
                    onChange={(e) => {
                      setEmailForm({
                        ...emailForm,
                        name: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="email"
                    className="contact-form-field w-input"
                    maxLength={256}
                    name="email"
                    data-name="Email"
                    placeholder="Email"
                    id="email"
                    required
                    value={emailForm.email}
                    onChange={(e) =>
                      setEmailForm({
                        ...emailForm,
                        email: e.target.value,
                      })
                    }
                  />
                  <textarea
                    className="contact-form-field w-input"
                    maxLength={256}
                    rows={3}
                    name="Zpr-va"
                    data-name="Zpráva"
                    placeholder="Zpráva"
                    id="zprava"
                    required
                    value={emailForm.message}
                    onChange={(e) =>
                      setEmailForm({
                        ...emailForm,
                        message: e.target.value,
                      })
                    }
                  />
                  <input
                    type="submit"
                    value={"Odeslat"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      sendEmail();
                    }}
                    className="button w-button"
                  />
                </form>
              </div>
            </div>
            <div className="contact-column contact-column-right">
              <div className="contact-box">
                <img
                  src="images/kontakt.webp"
                  loading="lazy"
                  alt="kontakt"
                  className="contact-woman"
                  title="title_images/kontakt.webp"
                />
                <div className="w-layout-grid grid">
                  <img
                    src="images/clock.svg"
                    loading="lazy"
                    id="w-node-_7d1f7c1d-a615-71f4-eb5f-a01936420cd3-ff0fd418"
                    alt="clock"
                    title="title_images/clock.svg"
                    className="contact-icon"
                  />
                  <div
                    id="w-node-_66bbbe6f-0d33-cc6c-a655-c9522da047cb-ff0fd418"
                    className="contact-text"
                  >
                    24/7
                  </div>
                  <img
                    src="images/email.svg"
                    loading="lazy"
                    id="w-node-_8f841688-0e13-e06e-c90f-d3ba98a7bd6e-ff0fd418"
                    alt="email"
                    title="title_images/email.svg"
                    className="contact-icon"
                  />
                  <a
                    id="w-node-_5126b11b-ce07-37ac-1299-5924b752cbfd-ff0fd418"
                    href="mailto:info@restia.cz"
                    className="contact-text"
                  >
                    info@restia.cz
                  </a>
                  <img
                    src="images/mobile.svg"
                    loading="lazy"
                    id="w-node-e39f08cc-9ee5-9e0b-ddc4-433fa21a8d69-ff0fd418"
                    alt="mobile"
                    title="title_images/mobile.svg"
                    className="contact-icon"
                  />
                  <a
                    id="w-node-_4f64c683-ba61-19eb-7023-59fb12eb857a-ff0fd418"
                    href="tel:+420778980006"
                    className="contact-text"
                  >
                    +420 778 980 006
                  </a>
                  <img
                    src="images/location.svg"
                    loading="lazy"
                    id="w-node-_549a52d5-6514-6f3c-995b-4d798810d08a-ff0fd418"
                    alt="location"
                    title="title_images/location.svg"
                    className="contact-icon"
                  />
                  <a
                    id="w-node-_3d2cff9a-dc77-6819-ff80-baee1aa69113-ff0fd418"
                    href="https://mapy.cz/s/pufetamulu"
                    className="contact-text"
                  >
                    Jablonecká 17/9,
                    <br />
                    460 07 Liberec
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
