import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
  title: string;
  selectedPage: string;
}

const Header = (props: HeaderProps) => {
  const links = [
    { title: 'domů', href: '/' },
    { title: 'produkty', href: '/produkty' },
    // { title: "FAQ", href: "/faq" },
    { id: 'eshop', title: 'eshop', href: '/' },
    { title: 'kontakty', href: '/kontakt' },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState('CZ');

  const [title, selectedPage] = [props.title, props.selectedPage];

  useEffect(() => {
    let domain = window.location.hostname;
    if (domain && domain.includes('.sk')) {
      setSelectedLanguage('SK');
    }
  });
  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar-logo-left-container shadow-three w-nav"
    >
      <div className="container header-container">
        <div className="navbar-wrapper">
          <Link to="/" className="navbar-brand w-nav-brand">
            <img
              src="images/restia-white.svg"
              loading="lazy"
              className="image-8"
              alt="restia logo"
              title="RESTIA"
            />
          </Link>
          <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
            <ul role="list" className="nav-menu w-list-unstyled">
              {links.map((link) => (
                <li className="list-item">
                  {link.id !== 'eshop' ? (
                    <Link
                      to={link.href}
                      className={`nav-link ${
                        selectedPage === link.title ? 'w--current' : ''
                      }`}
                    >
                      {link.title}
                    </Link>
                  ) : (
                    <a
                      className="anchor-style-removed"
                      id={link.id}
                      href="https://online.restia.cz/restiaeshop"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  )}
                </li>
              ))}

              <li className="nav-link-accent">
                <a
                  id="login"
                  href="https://admin.restia.cz"
                  className="button button-login w-inline-block"
                >
                  <img
                    src="images/person.svg"
                    loading="lazy"
                    className="image-3"
                    alt="uživatelské přihlášení do administrace"
                    title="Administrace"
                  />
                  <div className="text-block">login</div>
                </a>
              </li>
              <li className="list-item">
                <div className="language-dropdown">
                  {selectedLanguage === 'SK' ? (
                    <>
                      <img
                        src="images/lang_flag2.svg"
                        alt="Slovenština"
                        title=""
                        loading="lazy"
                        className=""
                      />
                      <span>SK</span>
                    </>
                  ) : (
                    <>
                      <img
                        src="images/lang_flag1.svg"
                        alt="Čeština"
                        title=""
                        loading="lazy"
                        className=""
                      />
                      <span>CZ</span>
                    </>
                  )}

                  <div className="dropdown-content">
                    <a href="https://restia.cz/" target={'_self'}>
                      <img
                        src="images/lang_flag1.svg"
                        alt="Čeština"
                        title=""
                        loading="lazy"
                        className=""
                      />
                      <span>Čeština</span>
                    </a>
                    <a href="https://restia.sk/" target={'_self'}>
                      <img
                        src="images/lang_flag2.svg"
                        alt="Slovenština"
                        title=""
                        loading="lazy"
                        className=""
                      />
                      <span>Slovenština</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <div className="menu-button menu-button-2 w-nav-button">
            <div className="w-icon-nav-menu" />
          </div>
        </div>
        <h1 className="heading-1">{title}</h1>
      </div>
    </div>
  );
};

export default Header;
