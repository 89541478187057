import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  EMAILJS_CALCULATOR_TEMPLATE_ID,
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVICE_ID,
} from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Calculator = () => {
  const [page, setPage] = useState(1);
  const goBack = () => setPage(page - 1);
  const goNext = () => setPage(page + 1);

  const pageNames = [
    "Začínáme",
    "Rozvoz",
    "Dispečink",
    "Kurýři",
    "POS",
    "Sumarizace",
    "Kontakty",
  ];

  const [deliveries, setDeliveries] = useState({
    DJ: false,
    Wolt: false,
    Bolt: false,
    "vlastní web": false,
  });

  const [deliveriesPrice, setDeliveriesPrice] = useState(0);

  useEffect(() => {
    let deliveriesCount = Array.from(Object.values(deliveries)).filter(
      (x) => x === true
    ).length;
    switch (deliveriesCount) {
      case 0:
        setDeliveriesPrice(0);
        break;
      case 1:
        setDeliveriesPrice(800);
        break;
      default:
        setDeliveriesPrice(1800);
        break;
    }
  }, [deliveries]);

  const [couriersCount, setCouriersCount] = useState(0);

  const decreaseCouriersCount = () => {
    if (couriersCount > 0) {
      setCouriersCount(couriersCount - 1);
    }
  };

  const increaseCouriersCount = () => {
    setCouriersCount(couriersCount + 1);
  };

  const [couriersPrice, setCouriersPrice] = useState(0);

  const [dispatching, setDispatching] = useState({
    Neřízený: false,
    Řízený: false,
  });

  useEffect(() => {
    if (
      (dispatching.Neřízený && couriersCount > 3) ||
      (dispatching.Řízený && couriersCount > 3)
    ) {
      if (dispatching.Neřízený) {
        setCouriersPrice((couriersCount - 3) * 350);
      } else if (dispatching.Řízený) {
        setCouriersPrice((couriersCount - 3) * 750);
      }
    } else {
      setCouriersPrice(0);
    }
  }, [couriersCount, dispatching]);

  const [dispatchingPrice, setDispatchingPrice] = useState(0);

  useEffect(() => {
    if (!dispatching.Neřízený && !dispatching.Řízený) {
      setDispatchingPrice(0);
    } else {
      if (dispatching.Neřízený) {
        setDispatchingPrice(1500);
      } else if (dispatching.Řízený) {
        setDispatchingPrice(2000);
      }
    }
  }, [dispatching]);

  const [pos, setPos] = useState({
    Dotykačka: false,
    Mefisto: false,
    Storyous: false,
    "RKeeper-cz": false,
    jiný: false,
  });

  const posInit = {
    Dotykačka: false,
    Mefisto: false,
    Storyous: false,
    "RKeeper-cz": false,
    jiný: false,
  };

  const [posPrice, setPosPrice] = useState(0);

  const parseDeliveries = () => {
    let html = "";
    const values = Object.values(deliveries);
    let keys = Object.keys(deliveries);
    keys = keys.filter((name, index) => values[index] === true);
    keys.forEach((n) => {
      let n_modified = n.replace("-", "").replace(" ", "_").toLowerCase();
      html += `<img
					src="images/${n_modified}0.5x.webp"
					loading="lazy"
					className="summary-form-item-value-picture"
				/>`;
    });
    return html;
  };

  const parseFinal = (obj: object) => {
    const values = Object.values(obj);
    let keys = Object.keys(obj);
    keys = keys.filter((name, index) => values[index] === true);
    return keys.join(", ");
  };

  const totalPrice =
    deliveriesPrice + couriersPrice + dispatchingPrice + posPrice;

  const [emailForm, setEmailForm] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });

  const toastId = useRef<any>();

  const sendEmail = () => {
    const sendingToast = () =>
      (toastId.current = toast("Odesílání poptávky...", {
        autoClose: false,
      }));
    sendingToast();

    fetch("https://icrm.restia.cz/api/calc-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "02a232c550e87b1cc8c95b070d9f69a2",
      },
      body: JSON.stringify({
        ...emailForm,
        delivery: parseFinal(deliveries),
        couriers: couriersCount,
        pos: parseFinal(pos),
        dispatching: parseFinal(dispatching),
        total_price: totalPrice,
      }),
    })
      .then((response) => {
        // handle response - just json { "message": "success.." }
      })
      .catch((error) => {
        // handle error - may be 401 Unauthorized
      });

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_CALCULATOR_TEMPLATE_ID,
        {
          ...emailForm,
          delivery: parseFinal(deliveries),
          couriers: couriersCount,
          pos: parseFinal(pos),
          dispatching: parseFinal(dispatching),
          total_price: totalPrice,
        },
        EMAILJS_PUBLIC_KEY
      )
      .then((res) => {
        setEmailForm({
          name: "",
          surname: "",
          email: "",
          phone: "",
        });
        toast.dismiss(toastId.current);
        setTimeout(() => {
          toast.success(
            "Děkujeme za odeslání poptávky. Potvrzení najdete ve vašem zadaném emailu."
          );
        }, 300);
      })
      .catch((err) => {
        toast.dismiss(toastId.current);
        setTimeout(() => {
          toast.error("Něco se pokazilo. Zkuste to prosím znovu.");
        }, 300);
      });
  };

  return (
    <>
      <ToastContainer
        position="bottom-left"
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={true}
        theme="light"
      />
      <div className="w-layout-grid calculator-grid">
        <div className="calculator-side calculator-side-left">
          <ol role="list" className="calculator-list calculator-content-list">
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-1">
              Začínáme
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-2">
              Rozvoz
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-3">
              Dispečink
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-4">
              Kurýři
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-5">
              POS
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-6">
              Sumarizace
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-7">
              Kontakty
            </li>
            <li className="calculator-item calculator-content-list-item calculator-content-list-item-mobile">
              <b>{page}.</b>&nbsp;{pageNames[page - 1]}
            </li>
          </ol>
        </div>
        <div className="calculator-center">
          <div className="container calculator-container w-container">
            <div
              className={`calculator-content calculator-content-1 ${
                page === 1 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Spočítejte si cenu</h3>
              <p className="paragraph-2">
                Dejte nám vědět, jakou rozvozovou platformu využíváte a my vám
                nabídneme nejlepší řešení. Vybrat můžete jednu nebo klidně
                všechny. Pokud plánujete platformy rozšířit, vyberte je rovnou
                teď.
              </p>
              <a
                href="#"
                className="button button-green w-button"
                onClick={() => goNext()}
              >
                Začít
              </a>
            </div>
            <div
              className={`calculator-content calculator-content-2 ${
                page === 2 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Pro koho vaříte?</h3>
              <p className="paragraph-2">
                Dejte nám vědět, jakou rozvozovou platformu využíváte a my vám
                nabídneme nejlepší řešení. Vybrat můžete jednu nebo klidně
                všechny. Pokud plánujete platformy rozšířit, vyberte je rovnou
                teď.
              </p>
              <div className="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                >
                  <div className="w-layout-grid select-platforms-grid">
                    <img
                      src="images/dj0.5x.webp"
                      loading="lazy"
                      id="w-node-_103f8cde-c060-98e6-e9d4-08629dae98c2-ff0fd418"
                      alt="foodora logo"
                      title="Foodora"
                    />
                    <img
                      src="images/wolt0.5x.webp"
                      loading="lazy"
                      id="w-node-_09caabf0-f4de-4f71-8a4f-e0d15cbca0ca-ff0fd418"
                      alt="wolt logo"
                      title="Wolt"
                    />
                    <img
                      src="images/bolt0.5x.webp"
                      loading="lazy"
                      id="w-node-_93acb37d-28b7-2515-68c9-d5d73bc236ad-ff0fd418"
                      alt="bolt logo"
                      title="Bolt"
                    />
                    <img
                      src="images/vlastní_web0.5x.webp"
                      loading="lazy"
                      id="w-node-cef1a724-168c-d9da-5489-2d88d78a0160-ff0fd418"
                      alt="vlastní web logo"
                      title="Vlastní eshop"
                    />
                    <a
                      href="#"
                      data-ix="toggle"
                      className={`toggle-button w-inline-block ${
                        deliveries.DJ ? "is-active" : ""
                      }`}
                      onClick={() =>
                        setDeliveries({
                          ...deliveries,
                          DJ: !deliveries.DJ,
                        })
                      }
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      className={`toggle-button w-inline-block ${
                        deliveries.Wolt ? "is-active" : ""
                      }`}
                      onClick={() =>
                        setDeliveries({
                          ...deliveries,
                          Wolt: !deliveries.Wolt,
                        })
                      }
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      className={`toggle-button w-inline-block ${
                        deliveries.Bolt ? "is-active" : ""
                      }`}
                      onClick={() =>
                        setDeliveries({
                          ...deliveries,
                          Bolt: !deliveries.Bolt,
                        })
                      }
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      className={`toggle-button w-inline-block ${
                        deliveries["vlastní web"] ? "is-active" : ""
                      }`}
                      onClick={() =>
                        setDeliveries({
                          ...deliveries,
                          "vlastní web": !deliveries["vlastní web"],
                        })
                      }
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                  </div>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div
                id="w-node-cdf65033-fe67-042f-ad1e-e8a98a17e430-ff0fd418"
                className="calculator-navigations"
              >
                <div
                  className="calculator-navigation calculator-navigation-back"
                  onClick={() => goBack()}
                >
                  <div className="calculator-navigation-text calculator-navigation-text-back">
                    zpět
                  </div>
                  <div className="button arrow-button arrow-button-back">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                </div>
                <div
                  className="calculator-navigation calculator-navigation-next"
                  onClick={() => goNext()}
                >
                  <div className="button arrow-button arrow-button-next">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                  <div className="calculator-navigation-text calculator-navigation-text-next">
                    další
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`calculator-content calculator-content-3 ${
                page === 3 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Dispečink</h3>
              <p className="paragraph-2">Budete používat dispečink?</p>
              <div className="form-block-2 w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                >
                  <div className="w-layout-grid select-dispatching-grid">
                    <div
                      id="w-node-_66c6d889-8dc5-8968-cc49-ef63d94f2843-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        Neřízený dispečink
                      </h4>
                      <div className="select-dispatching-option-description">
                        Kurýři si plánují trasu sami
                      </div>
                    </div>
                    <div
                      id="w-node-_94c46fe6-6c03-6fbd-703e-13748a84829c-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        Řízený dispečink
                      </h4>
                      <div className="select-dispatching-option-description">
                        Kurýrům plánuje trasy manažer/číšník
                      </div>
                    </div>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_03003ab7-e5dc-7c9c-a7d9-0048687f20d4-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        dispatching.Neřízený ? "is-active" : ""
                      }`}
                      onClick={() => {
                        if (dispatching.Neřízený) {
                          setDispatching({
                            Řízený: false,
                            Neřízený: false,
                          });
                        } else {
                          setDispatching({
                            Řízený: false,
                            Neřízený: true,
                          });
                        }
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_03003ab7-e5dc-7c9c-a7d9-0048687f20d7-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        dispatching.Řízený ? "is-active" : ""
                      }`}
                      onClick={() => {
                        if (dispatching.Řízený) {
                          setDispatching({
                            Řízený: false,
                            Neřízený: false,
                          });
                        } else {
                          setDispatching({
                            Řízený: true,
                            Neřízený: false,
                          });
                        }
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                  </div>
                </form>

                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div className="calculator-navigations">
                <div
                  className="calculator-navigation calculator-navigation-back"
                  onClick={() => goBack()}
                >
                  <div className="calculator-navigation-text calculator-navigation-text-back">
                    zpět
                  </div>
                  <div className="button arrow-button arrow-button-back">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                </div>
                <div
                  className="calculator-navigation calculator-navigation-next"
                  onClick={() => goNext()}
                >
                  <div className="button arrow-button arrow-button-next">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                  <div className="calculator-navigation-text calculator-navigation-text-next">
                    další
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`calculator-content calculator-content-4 ${
                page === 4 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Kolik máte kurýrů?</h3>
              <p className="paragraph-2">
                Pokud si zajišťujete rozvoz pomocí svých řidičů, zadejte počet
                kurýrů na jedné směně.
                <br />
                <br />
                Jestliže své kurýry nemáte, tento krok můžete přeskočit.
              </p>
              <div className="courier-select-container">
                <a
                  href="#"
                  className="button courier-select-button w-button"
                  onClick={() => decreaseCouriersCount()}
                >
                  -
                </a>
                <div className="courier-select-number">{couriersCount}</div>
                <a
                  href="#"
                  className="button courier-select-button w-button"
                  onClick={() => increaseCouriersCount()}
                >
                  +
                </a>
              </div>
              <div className="calculator-navigations">
                <div
                  className="calculator-navigation calculator-navigation-back"
                  onClick={() => goBack()}
                >
                  <div className="calculator-navigation-text calculator-navigation-text-back">
                    zpět
                  </div>
                  <div className="button arrow-button arrow-button-back">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                </div>
                <div
                  className="calculator-navigation calculator-navigation-next"
                  onClick={() => goNext()}
                >
                  <div className="button arrow-button arrow-button-next">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                  <div className="calculator-navigation-text calculator-navigation-text-next">
                    další
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`calculator-content calculator-content-5 ${
                page === 5 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Pokladní systém</h3>
              <p className="paragraph-2">
                Zde prosím zadejte, jaký pokladní systém používáte.
              </p>
              <div className="form-block-2 w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  className="form-2"
                >
                  <div className="w-layout-grid select-pos-grid">
                    <div
                      id="w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1eec-ff0fd418"
                      className="select-pos-option"
                    >
                      <h4 className="heading-4 select-pos-option-heading">
                        Dotykačka
                      </h4>
                    </div>
                    <div
                      id="w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef1-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        Mefisto
                      </h4>
                    </div>
                    <div
                      id="w-node-_2f3d6414-6edd-2c48-fd40-c00f6a933083-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        Storyous
                      </h4>
                    </div>
                    <div
                      id="w-node-a6e34e4d-5ba4-e03c-89df-c6f6a2f43e25-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        RKeeper-cz
                      </h4>
                    </div>
                    <div
                      id="w-node-cdd3ba07-ce81-699b-bb96-f4cfeaba902f-ff0fd418"
                      className="select-dispatching-option"
                    >
                      <h4 className="heading-4 select-dispatching-option-heading">
                        jiný
                      </h4>
                    </div>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef6-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        pos.Dotykačka ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setPos({
                          ...posInit,
                          Dotykačka: !pos.Dotykačka,
                        });
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef9-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        pos.Mefisto ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setPos({
                          ...posInit,
                          Mefisto: !pos.Mefisto,
                        });
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-d93ae43c-6162-49df-cacd-24af8ddf8a98-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        pos.Storyous ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setPos({
                          ...posInit,
                          Storyous: !pos.Storyous,
                        });
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_8d38dbec-c80b-db89-9df6-403095b870fa-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        pos["RKeeper-cz"] ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setPos({
                          ...posInit,
                          "RKeeper-cz": !pos["RKeeper-cz"],
                        });
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                    <a
                      href="#"
                      data-ix="toggle"
                      id="w-node-_0823fe17-3824-8dde-49d4-38e47d0c8c36-ff0fd418"
                      className={`toggle-button w-inline-block ${
                        pos.jiný ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setPos((pos) => ({
                          ...posInit,
                          jiný: !pos.jiný,
                        }));
                      }}
                    >
                      <div data-ix="toggle" className="toggle-button-fill" />
                      <div className="button-toggle" />
                    </a>
                  </div>
                </form>

                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div className="calculator-navigations">
                <div
                  className="calculator-navigation calculator-navigation-back"
                  onClick={() => goBack()}
                >
                  <div className="calculator-navigation-text calculator-navigation-text-back">
                    zpět
                  </div>
                  <div className="button arrow-button arrow-button-back">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                </div>
                <div
                  className="calculator-navigation calculator-navigation-next"
                  onClick={() => goNext()}
                >
                  <div className="button arrow-button arrow-button-next">
                    <img
                      src="images/arrow.webp"
                      loading="lazy"
                      className="arrow-button-icon"
                      alt="pokračovat"
                      title="pokračovat"
                    />
                  </div>
                  <div className="calculator-navigation-text calculator-navigation-text-next">
                    další
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`calculator-content calculator-content-6-1 ${
                page === 6 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Sumarizace</h3>
              <p className="paragraph-2">Přehled všech vašich voleb.</p>
              <div className="summary-form w-form">
                <form
                  id="email-form-3"
                  name="email-form-3"
                  data-name="Email Form 3"
                  method="get"
                >
                  <div className="w-layout-grid summary-form-grid">
                    <div
                      id="w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce751-ff0fd418"
                      className="summary-form-item summary-form-item-1"
                    >
                      <div className="summary-form-item-text">
                        <strong className="summary-form-item-text">
                          Rozvoz
                        </strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parseDeliveries(),
                          }}
                        ></div>
                        <a
                          href="#"
                          className="link-block-2 w-inline-block"
                          onClick={() => setPage(2)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="summary-form-input summary-form-input-1 w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce75a-ff0fd418 w-input"
                      maxLength={256}
                      name="jmeno-3"
                      data-name="Jmeno 3"
                      placeholder="Jméno"
                      id="jmeno-3"
                      value={emailForm.name}
                      onChange={(e) => {
                        setEmailForm({
                          ...emailForm,
                          name: e.target.value,
                        });
                      }}
                      required
                    />
                    <div
                      id="w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce75b-ff0fd418"
                      className="summary-form-item summary-form-item-1"
                    >
                      <div className="summary-form-item-text">
                        <strong>Kurýři</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{couriersCount}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(4)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="summary-form-input summary-form-input-1 w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce764-ff0fd418 w-input"
                      maxLength={256}
                      name="prijmeni-2"
                      data-name="Prijmeni 2"
                      placeholder="Příjmení"
                      id="prijmeni-2"
                      value={emailForm.surname}
                      onChange={(e) => {
                        setEmailForm({
                          ...emailForm,
                          surname: e.target.value,
                        });
                      }}
                      required
                    />
                    <div
                      id="w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce765-ff0fd418"
                      className="summary-form-item summary-form-item-1"
                    >
                      <div className="summary-form-item-text">
                        <strong>POS</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{parseFinal(pos)}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(5)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="tel"
                      className="summary-form-input summary-form-input-1 w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce76e-ff0fd418 w-input"
                      maxLength={256}
                      name="telefon-3"
                      data-name="Telefon 3"
                      placeholder="Telefon"
                      id="telefon-3"
                      value={emailForm.phone}
                      onChange={(e) => {
                        setEmailForm({
                          ...emailForm,
                          phone: e.target.value,
                        });
                      }}
                      required
                    />
                    <div
                      id="w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce76f-ff0fd418"
                      className="summary-form-item summary-form-item-1"
                    >
                      <div className="summary-form-item-text">
                        <strong>Dispečink</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{parseFinal(dispatching)}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(3)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="email"
                      className="summary-form-input summary-form-input-1 w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce778-ff0fd418 w-input"
                      maxLength={256}
                      name="Email-3"
                      data-name="Email 3"
                      placeholder="Email"
                      id="Email-3"
                      value={emailForm.email}
                      onChange={(e) => {
                        setEmailForm({
                          ...emailForm,
                          email: e.target.value,
                        });
                      }}
                      required
                    />
                    <div className="calculator-navigations">
                      <div
                        id="w-node-e258aedf-aac8-0465-3915-57d19260b7c9-ff0fd418"
                        className="calculator-navigation calculator-navigation-6 calculator-navigation	-6-1"
                        onClick={() => goBack()}
                      >
                        <div className="calculator-navigation calculator-navigation-back calculator-navigation-back-6 calculatov-navigation-back-6-1">
                          <div className="calculator-navigation-text calculator-navigation-text-back">
                            zpět
                          </div>
                          <div className="button arrow-button arrow-button-back">
                            <img
                              src="images/arrow.webp"
                              loading="lazy"
                              className="arrow-button-icon"
                              alt="pokračovat"
                              title="pokračovat"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="calculator-navigation calculator-navigation-next calculator-navigation-next-6 calculator-navigation-next-6-2"
                        onClick={() => goNext()}
                      >
                        <div className="button arrow-button arrow-button-next">
                          <img
                            src="images/arrow.webp"
                            loading="lazy"
                            className="arrow-button-icon"
                            alt="pokračovat"
                            title="pokračovat"
                          />
                        </div>
                        <div className="calculator-navigation-text calculator-navigation-text-next">
                          další
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      value={"Odeslat"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        sendEmail();
                      }}
                      id="w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce77e-ff0fd418"
                      className="button summary-form-submit-button summary-form-submit-button-6-1 w-button"
                    />
                  </div>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`calculator-content calculator-content-6-2 ${
                page === 7 ? "is-active" : ""
              }`}
            >
              <h3 className="heading-3">Kontaktní údaje</h3>
              <p className="paragraph-2">Zadejte kontaktní údaje.</p>
              <div className="summary-form w-form">
                <form
                  id="email-form-3"
                  name="email-form-3"
                  data-name="Email Form 3"
                  method="get"
                >
                  <div className="w-layout-grid summary-form-grid summary-form-grid-6-2">
                    <div
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a08-ff0fd418"
                      className="summary-form-item summary-form-item-2"
                    >
                      <div className="summary-form-item-text">
                        <strong className="summary-form-item-text">
                          Rozvoz
                        </strong>
                      </div>
                      <div className="summary-form-item-value">
                        <img
                          src="images/dj0.5x.webp"
                          loading="lazy"
                          className="summary-form-item-value-picture"
                          alt="foodora logo"
                          title="Foodora"
                        />
                        <img
                          src="images/wolt0.5x.webp"
                          loading="lazy"
                          className="summary-form-item-value-picture"
                          alt="wolt logo"
                          title="Wolt"
                        />
                        <a href="#" className="link-block-2 w-inline-block">
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="summary-form-input summary-form-input-2 w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a11-ff0fd418 w-input"
                      maxLength={256}
                      name="jmeno-3"
                      data-name="Jmeno 3"
                      placeholder="Jméno"
                      id="jmeno-3"
                      required
                    />
                    <div
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a12-ff0fd418"
                      className="summary-form-item summary-form-item-2"
                    >
                      <div className="summary-form-item-text">
                        <strong>Kurýři</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{couriersCount}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(3)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="summary-form-input summary-form-input-2 w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a1b-ff0fd418 w-input"
                      maxLength={256}
                      name="prijmeni-2"
                      data-name="Prijmeni 2"
                      placeholder="Příjmení"
                      id="prijmeni-2"
                      required
                    />
                    <div
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a1c-ff0fd418"
                      className="summary-form-item summary-form-item-2"
                    >
                      <div className="summary-form-item-text">
                        <strong>POS</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{parseFinal(pos)}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(5)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="tel"
                      className="summary-form-input summary-form-input-2 w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a25-ff0fd418 w-input"
                      maxLength={256}
                      name="telefon-3"
                      data-name="Telefon 3"
                      placeholder="Telefon"
                      id="telefon-3"
                      required
                    />
                    <div
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a26-ff0fd418"
                      className="summary-form-item summary-form-item-2"
                    >
                      <div className="summary-form-item-text">
                        <strong>Dispečink</strong>
                      </div>
                      <div className="summary-form-item-value">
                        <div>{parseFinal(dispatching)}</div>
                        <a
                          className="w-inline-block"
                          onClick={() => setPage(4)}
                        >
                          <img
                            src="images/pencil.webp"
                            loading="lazy"
                            className="summary-form-item-value-edit-button"
                            alt="upravit"
                            title="upravit"
                          />
                        </a>
                      </div>
                    </div>
                    <input
                      type="email"
                      className="summary-form-input summary-form-input-2 w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a2f-ff0fd418 w-input"
                      maxLength={256}
                      name="Email-3"
                      data-name="Email 3"
                      placeholder="Email"
                      id="Email-3"
                      required
                    />
                    <div
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a30-ff0fd418"
                      className="calculator-navigation calculator-navigation-back"
                      onClick={() => goBack()}
                    >
                      <div className="calculator-navigation-text calculator-navigation-text-back">
                        zpět
                      </div>
                      <div className="button arrow-button arrow-button-back">
                        <img
                          src="images/arrow.webp"
                          loading="lazy"
                          className="arrow-button-icon"
                          alt="pokračovat"
                          title="pokračovat"
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="Odeslat"
                      data-wait="Odesílání..."
                      id="w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a35-ff0fd418"
                      className="button summary-form-submit-button summary-form-submit-button-6-2 w-button"
                    />
                  </div>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calculator-side calculator-side-right">
          <ul
            role="list"
            className="calculator-list calculator-price-list w-list-unstyled"
          >
            <li className="calculator-item calculator-price-item calculator-price-item-heading">
              <strong className="bold-text">Průběžná cena:</strong>
            </li>
            <li className="calculator-item calculator-price-item">
              + {deliveriesPrice} Kč
            </li>
            <li className="calculator-item calculator-price-item">
              + {dispatchingPrice} Kč
            </li>
            <li className="calculator-item calculator-price-item">
              + {couriersPrice} Kč
            </li>
            <li className="calculator-item calculator-price-item is-hidden--text">
              + {posPrice} Kč
            </li>
            <li className="calculator-item calculator-price-item calculator-price-item-final">
              <span className="calculator-final-price">
                {deliveriesPrice + couriersPrice + dispatchingPrice + posPrice}{" "}
                Kč
              </span>
              měsíčně
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Calculator;
