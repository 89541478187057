/* eslint-disable */

import React, { createRef } from "react";
import Calculator from "../components/Calculator";
import Footer from "../components/Footer";
import Header from "../components/Header";
import YouTube from "react-youtube";
import { transformProxies } from "./helpers";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63553f7f4f3fdf4d450fd417"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class IndexView extends React.Component {
  constructor() {
    super();
    this.state = {
      Index: IndexView.Controller,
      isPlaying: false,
      videoElement: null,
    };
    this.toastId = createRef(null);
  }

  _onReady(event) {
    this.setState({ ...this.state, videoElement: event.target });
  }

  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("..controllers/IndexController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = IndexView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "63553f7f4f3fdf1cff0fd418";
    htmlEl.dataset["wfSite"] = "63553f7f4f3fdf4d450fd417";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  componentDidUpdate() {
    this.videoElement &&
      this.videoElement.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          this.state.videoElement.pauseVideo();
        }
      });

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.state.videoElement.pauseVideo();
      }
    });
  }

  render() {
    const proxies =
      IndexView.Controller !== IndexView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <Helmet>
          <title>RESTIA | Jeden systém pro celou restuauraci! | RESTIA</title>
          <meta
            name="description"
            content="Spolehlivý objednávkový systém pro pizzerie a restaurace, který šetří čas i peníze ✔️ Z jednoho místa obsloužíte wolt, bolt, foodora, vlastní rozvoz a také si zařídíte externí rozvoz ✔️ Efektivní řízení restaurace, které vám přinese snížení nákladů 💰💲 RESTIA software, který chutná."
          />
          <meta
            name="keywords"
            content="RESTIA, objednávkový systém, eshop pro restaurace, aplikace pro řidiče, dispečink, menu manager, menu import, všechny objednávky na jednom místě, integrace s POS, wolt, bolt, foodora, damejidlo"
          />
        </Helmet>
        <style
        //   dangerouslySetInnerHTML={{
        //     __html: `
        //   @import url(C:\\css\\normalize.css);
        //   @import url(C:\\css\\webflow.css);
        //   @import url(C:\\css\\restia.webflow.css);
        // `,
        //   }}
        />
        <span className="af-view">
          <div>
            <img
              src="images/path4722.svg"
              alt="background"
              title=""
              loading="lazy"
              className="header-pic"
            />
            <div className="navbar wf-section">
              <Header
                title={"Online objednávky z webu až do kuchyně"}
                selectedPage={"homepage"}
              />
            </div>
            <div className="header-info-section wf-section">
              <div className="container header-info-container w-container">
                <div className="header-info">
                  <img
                    src="images/7_let_na_trhu.svg"
                    loading="lazy"
                    alt="7 let na trhu"
                    title="7 let na trhu"
                    className="header-info-image"
                  />
                  <div className="header-info-value">7 let</div>
                  <div className="header-info-description">na trhu</div>
                </div>
                <div className="header-info">
                  <img
                    src="images/24_7_podpora.svg"
                    loading="lazy"
                    alt="podpora zákazníkům 24/7"
                    title="Nonstop podpora"
                    className="header-info-image"
                  />
                  <div className="header-info-value">24/7</div>
                  <div className="header-info-description">podpora</div>
                </div>
                <div className="header-info">
                  <img
                    src="images/150_restauraci.svg"
                    loading="lazy"
                    alt="spolupracujeme s více než 150 restauracemi"
                    title="150+ restaurací"
                    className="header-info-image"
                  />
                  <div className="header-info-value">150+</div>
                  <div className="header-info-description">restaurací</div>
                </div>
              </div>
            </div>
            <section className="hero wf-section">
              <div className="container">
                <h2 className="heading-2 heading-2-hero">Co děláme?</h2>
                <div className="hero-wrapper">
                  <div className="hero-split hero-split-left">
                    <h3 className="heading-3">
                      Šetříme čas a snižujeme chybovost
                    </h3>
                    <p className="hero-description">
                      Objednávky z rozvozových platforem přenášíme do pokladního
                      systému automaticky, bez potřeby ručního markování a bez
                      chyb. Dokážeme je i sami potvrdit s předvoleným časem,
                      nebo dle aktuální vytíženosti kuchyně.
                    </p>
                    <h3 className="heading-3">Zjednodušujeme správu menu</h3>
                    <p className="hero-description">
                      Vaše menu můžete pohodlně upravovat na jednom místě a pak
                      ho jedním klikem hromadně nahrát na všechny platformy.
                      Stejně jednoduše můžete najednou schovat i jídla, která
                      Vám už došly.
                    </p>
                  </div>
                  <div className="hero-split hero-split-right">
                    <div
                      style={{
                        paddingTop: "56.17021276595745%",
                      }}
                      className="hero-video w-video w-embed"
                    >
                      <YouTube
                        className="embedly-embed"
                        videoId="8Z-w5ovjtPY"
                        opts={{
                          playerVars: {
                            cc_load_policy: 0,
                            iv_load_policy: 3,
                            modestbranding: 1,
                            rel: 0,
                            showinfo: 0,
                            disablekb: 0,
                            playsinline: 0,
                            fs: 0,
                            hl: "cz",
                            controls: 0,
                          },
                        }}
                        id="video"
                        onReady={(e) => this._onReady(e)}
                        onPlay={() => {
                          document.getElementById("video").requestFullscreen();
                        }}
                        onPause={() => document.exitFullscreen()}
                        onEnd={() => {
                          document.exitFullscreen();
                        }}
                      />
                    </div>
                    <img
                      src="images/tablet-left.webp"
                      alt="prezentační video ukázka"
                      title="title_images/tablet-left.webp"
                      className="tablet tablet-left"
                    />
                    <img
                      src="images/tablet-right.webp"
                      alt="prezentační video ukázka"
                      title="title_images/tablet-right.webp"
                      className="tablet tablet-right"
                    />
                    {/* <img
											src="images/tablet_1.webp"
											loading="lazy"
											srcSet="images/tablet_1-p-500.webp 500w"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 71vw, (max-width: 1279px) 55vw, (max-width: 1439px) 588.800048828125px, 45vw"
											alt
											className="tablet-image"
											onClick={() => {
												this.togglePlay()
											}}
										/> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="logos-section logos-section-apps wf-section">
              <img
                src="images/napojeni.webp"
                loading="lazy"
                alt="Propojení s platformou"
                title="Integrace všech platforem"
                className="kam-vas-napojime-man"
              />
              <div className="container">
                <div className="heading-wrapper">
                  <h2 className="heading-2">Kam Vás napojíme?</h2>
                  <p className="paragraph">
                    Spolupracujeme se všemi hlavními rozvozovými platformami na
                    trhu a dokážeme přijímat i objednávky z Vašeho firemního
                    webu. Pokud vlastní web pro objednávání zatím nemáte, tak
                    Vám ho na klíč obratem zřídíme.
                  </p>
                </div>
                <div className="w-layout-grid companies-grid">
                  <img
                    src="images/dj.webp"
                    loading="lazy"
                    id="w-node-_07104b23-ec5c-470f-a454-23624e889eed-ff0fd418"
                    alt="foodora logo"
                    title="Foodora"
                    className="company-logo"
                  />
                  <img
                    src="images/wolt.webp"
                    loading="lazy"
                    id="w-node-_96e16eac-5115-6b84-f197-7ff6db10011b-ff0fd418"
                    alt="wolt logo"
                    title="Wolt"
                    className="company-logo"
                  />
                  <img
                    src="images/bolt.webp"
                    loading="lazy"
                    id="w-node-_40fd9f8d-4fcc-cbe0-56d0-a599b1618335-ff0fd418"
                    alt="bolt logo"
                    title="Bolt"
                    className="company-logo"
                  />
                  <img
                    src="images/foodpanda.webp"
                    loading="lazy"
                    id="w-node-b7b08306-e02a-af7c-e7c0-6c37ee75bb6a-ff0fd418"
                    alt="foodpanda logo"
                    title="Foodpanda"
                    className="company-logo"
                  />
                  <img
                    src="images/jedlo.webp"
                    loading="lazy"
                    id="w-node-_271ec7a3-f07f-e6fc-4fd3-550e712839f3-ff0fd418"
                    alt="bistro.sk logo"
                    title="Bistro.sk"
                    className="company-logo"
                  />
                </div>
              </div>
            </section>
            <div className="workflow-section wf-section">
              <div className="container w-container">
                <h2 className="heading-2">Jak to celé funguje?</h2>
                <div className="w-layout-grid workflow-grid">
                  <img
                    src="images/prijmeme-objednavky-ze-vsech-platforem.svg"
                    loading="lazy"
                    id="w-node-_3d6a0472-74f0-5138-312c-5b57399c74c9-ff0fd418"
                    alt="přehled objednávek"
                    title="všechny objednávky z platforem na jednom místě"
                    className="workflow-grid-item workflow-grid-item-image"
                  />
                  <div
                    id="w-node-_69fe0a45-fe0c-d6a2-f0a4-386c43b889db-ff0fd418"
                    className="workflow-grid-item"
                  >
                    <h3 className="column-item-heading">
                      Přijmeme objednávky ze všech platforem
                    </h3>
                    <div className="column-item-description">
                      Zobrazí se pro přehlednost jenom v jednom tabletu
                    </div>
                  </div>
                  <div
                    id="w-node-_42e72d28-e75a-c372-06fd-73f000c0f57e-ff0fd418"
                    className="workflow-grid-item"
                  >
                    <h3 className="column-item-heading">
                      Potvrdíme je automaticky za Vás
                    </h3>
                    <div className="column-item-description">
                      S přednastaveným časem, nebo ručně dle potřeby
                    </div>
                  </div>
                  <img
                    src="images/potvrdime-je-automaticky-za-vas.svg"
                    loading="lazy"
                    id="w-node-_3e4f5339-9409-389c-a613-9b216d7504b1-ff0fd418"
                    alt="automatické potvrzení objednávky"
                    title="automatické potvrzení objednávky"
                    className="workflow-grid-item workflow-grid-item-image"
                  />
                  <img
                    src="images/objednavku-zpracujeme-a-bon-vyjede-v-kuchyni.svg"
                    loading="lazy"
                    id="w-node-_6b8b1d89-063a-94bf-5b49-c1447aaeac50-ff0fd418"
                    alt="zobrazení objednávky v kuchyni"
                    title="zobrazení objednávky v kuchyni"
                    className="workflow-grid-item workflow-grid-item-image"
                  />
                  <div
                    id="w-node-_6a5f4bbf-b306-5ab3-5a18-4ed04e5e17b2-ff0fd418"
                    className="workflow-grid-item"
                  >
                    <h3 className="column-item-heading">
                      Objednávku zpracujeme a bon vyjede v kuchyni
                    </h3>
                    <div className="column-item-description">
                      Po dovaření zašleme informaci kurýrům, že je objednávka
                      připravena
                    </div>
                  </div>
                  <div
                    id="w-node-_9b40ae57-8627-347b-3ff8-975083c0424a-ff0fd418"
                    className="workflow-grid-item"
                  >
                    <h3 className="column-item-heading">
                      Hotovou objednávku vyzvedne kurýr a doručí zákazníkovi
                    </h3>
                    <div className="column-item-description">
                      Pokud máte vlastní rozvoz, naplánujeme mu trasu, navedeme
                      aplikací v mobilu až k zákazníkovi a spočítáme kilometry
                    </div>
                  </div>
                  <img
                    src="images/hotovou-objednavku-vyzvedne-kuryr-a-doruci-zakaznikovi.svg"
                    loading="lazy"
                    id="w-node-_9bb47f99-7276-2a22-10fc-e4eee0742539-ff0fd418"
                    alt="rozvoz doručení objednávky k zákazníkovi"
                    title="doručení objednávky k zákazníkovi"
                    className="workflow-grid-item workflow-grid-item-image"
                  />
                </div>
                <div className="dashed-line dashed-line-1" />
                <div className="dashed-line dashed-line-2" />
                <div className="dashed-line dashed-line-3" />
              </div>
            </div>
            <section className="pricing-section wf-section" id="product-price">
              <div className="container">
                <div className="heading-wrapper">
                  <h2 className="heading-2">Na kolik to celé vyjde?</h2>
                  <p className="paragraph">
                    Cena našich služeb se liší podle toho, kolik máte
                    rozvozových platforem, jestli máte i vlastní kurýry a chcete
                    jim trasy plánovat, případně jestli se rozhodnete mít i
                    vlastní web.
                  </p>
                </div>
                <div className="pricing-wrapper">
                  <div
                    id="w-node-a15ad687-7a60-33e0-b6cd-13581cab91ed-ff0fd418"
                    className="pricing-card pricing-card-1"
                  >
                    <img
                      src="images/restia_lite.svg"
                      loading="lazy"
                      alt="RESTIA Lite logo"
                      title="RESTIA Lite produkt"
                      className="pricing-image"
                    />
                    <h3 className="pricing-title">RESTIA&nbsp;Lite</h3>
                    <div className="pricing-subtitle">cena od</div>
                    <div className="pricing-price">
                      <div className="pricing-price-value">800</div>
                      <div className="pricing-price-unit">Kč/měsíc</div>
                    </div>
                    <ul
                      role="list"
                      className="pricing-feature-list w-list-unstyled"
                    >
                      <li>
                        <div className="pricing-feature">menší restaurace</div>
                      </li>
                      <li>
                        <div className="pricing-feature">
                          jeden online profil
                        </div>
                      </li>
                      <li>
                        <div className="pricing-feature">statistiky</div>
                      </li>
                    </ul>
                    <Link
                      to={"/produkty#online-objednavky"}
                      className="button-outlined w-button"
                    >
                      Více zde
                    </Link>
                  </div>
                  <div
                    id="w-node-a15ad687-7a60-33e0-b6cd-13581cab920d-ff0fd418"
                    className="pricing-card pricing-card-2"
                  >
                    <img
                      src="images/restia_pro.svg"
                      loading="lazy"
                      alt="RESTIA Pro logo"
                      title="RESTIA Pro produkt"
                      className="pricing-image"
                    />
                    <h3 className="pricing-title pricing-title-featured">
                      RESTIA&nbsp;Pro
                    </h3>
                    <div className="pricing-subtitle">cena od</div>
                    <div className="pricing-price">
                      <div className="pricing-price-value">1 800</div>
                      <div className="pricing-price-unit">Kč/měsíc</div>
                    </div>
                    <ul
                      role="list"
                      className="pricing-feature-list w-list-unstyled"
                    >
                      <li>
                        <div className="pricing-feature pricing-feature-featured">
                          větší restaurace
                        </div>
                      </li>
                      <li>
                        <div className="pricing-feature pricing-feature-featured">
                          více online profilů
                        </div>
                      </li>
                      <li>
                        <div className="pricing-feature pricing-feature-featured">
                          vše v jednom tabletu
                        </div>
                      </li>
                    </ul>
                    <div className="pricing-tag pricing-tag-top">
                      doporučujeme
                    </div>
                    <Link
                      to={"/produkty#online-objednavky"}
                      className="button-outlined button-outlined-white w-button"
                    >
                      Více zde
                    </Link>

                    <Link
                      to="#calculator"
                      className="pricing-tag pricing-tag-bottom"
                    >
                      <img
                        src="images/settings.svg"
                        loading="lazy"
                        alt="konfigurátor služeb"
                        title="cenová kalkulačka"
                      />
                    </Link>
                  </div>
                  <div
                    id="w-node-_40d13eaf-1f8f-ed82-18b9-1a9a84fef63b-ff0fd418"
                    className="pricing-card pricing-card-3"
                  >
                    <img
                      src="images/dispecink_beta.svg"
                      loading="lazy"
                      alt="dispečink pro rozvoz objednávek"
                      title="dispečink pro rozvoz"
                      className="pricing-image pricing-image-dispatching"
                    />
                    <h3 className="pricing-title">Dispečink</h3>
                    <div className="pricing-subtitle">cena od</div>
                    <div className="pricing-price">
                      <div className="pricing-price-value">1 500</div>
                      <div className="pricing-price-unit">Kč/měsíc</div>
                    </div>
                    <ul
                      role="list"
                      className="pricing-feature-list w-list-unstyled"
                    >
                      <li>
                        <div className="pricing-feature">vlastní rozvoz</div>
                      </li>
                      <li>
                        <div className="pricing-feature">kniha jízd</div>
                      </li>
                      <li>
                        <div className="pricing-feature">
                          aplikace pro kurýry
                        </div>
                      </li>
                    </ul>
                    <Link
                      to={"/produkty#dispecink"}
                      className="button-outlined w-button"
                    >
                      Více zde
                    </Link>
                  </div>
                  <div
                    id="w-node-_19e684ad-181f-c73f-6bb8-462c3a04f1bd-ff0fd418"
                    className="pricing-card pricing-card-4"
                  >
                    <img
                      src="images/web.svg"
                      loading="lazy"
                      alt="vlastní eshop pro restaurace"
                      title="eshop restaurace"
                      className="pricing-image pricing-image-web"
                    />
                    <h3 className="pricing-title">Web</h3>
                    <div className="pricing-subtitle">cena od</div>
                    <div className="pricing-price">
                      <div className="pricing-price-value">900</div>
                      <div className="pricing-price-unit">Kč/měsíc</div>
                    </div>
                    <ul
                      role="list"
                      className="pricing-feature-list w-list-unstyled"
                    >
                      <li>
                        <div className="pricing-feature">
                          vlastní web na míru
                        </div>
                      </li>
                      <li>
                        <div className="pricing-feature">nahrávání menu</div>
                      </li>
                      <li>
                        <div className="pricing-feature">
                          ovládání přes tablet
                        </div>
                      </li>
                    </ul>
                    <Link
                      to={"/produkty#vlastni-eshop"}
                      className="button-outlined w-button"
                    >
                      Více zde
                    </Link>
                  </div>
                </div>
                <div
                  data-delay={4000}
                  data-animation="slide"
                  className="pricing-slider w-slider"
                  data-autoplay="false"
                  data-easing="ease"
                  data-hide-arrows="false"
                  data-disable-swipe="false"
                  data-autoplay-limit={0}
                  data-nav-spacing={50}
                  data-duration={500}
                  data-infinite="false"
                >
                  <div className="mask-2 w-slider-mask">
                    <div className="pricing-slider-slide-container w-slide">
                      <div className="pricing-card pricing-card-1">
                        <img
                          src="images/restia_lite.svg"
                          loading="lazy"
                          alt="RESTIA logo"
                          title="RESTIA"
                          className="pricing-image"
                        />
                        <h3 className="pricing-title">RESTIA&nbsp;Lite</h3>
                        <div className="pricing-subtitle">cena od</div>
                        <div className="pricing-price">
                          <div className="pricing-price-value">800</div>
                          <div className="pricing-price-unit">Kč/měsíc</div>
                        </div>
                        <ul
                          role="list"
                          className="pricing-feature-list w-list-unstyled"
                        >
                          <li>
                            <div className="pricing-feature">
                              menší restaurace
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature">
                              jeden online profil
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature">statistiky</div>
                          </li>
                        </ul>
                        <Link
                          to={"/produkty#online-objednavky"}
                          className="button-outlined w-button"
                        >
                          Více zde
                        </Link>
                      </div>
                    </div>
                    <div className="pricing-slider-slide-container w-slide">
                      <div className="pricing-card pricing-card-2">
                        <img
                          src="images/restia_pro.svg"
                          loading="lazy"
                          alt="RESTIA Pro logo"
                          title="RESTIA PRO"
                          className="pricing-image"
                        />
                        <h3 className="pricing-title pricing-title-featured">
                          RESTIA&nbsp;Pro
                        </h3>
                        <div className="pricing-subtitle">cena od</div>
                        <div className="pricing-price">
                          <div className="pricing-price-value">1 800</div>
                          <div className="pricing-price-unit">Kč/měsíc</div>
                        </div>
                        <ul
                          role="list"
                          className="pricing-feature-list w-list-unstyled"
                        >
                          <li>
                            <div className="pricing-feature pricing-feature-featured">
                              větší restaurace
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature pricing-feature-featured">
                              více online profilů
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature pricing-feature-featured">
                              vše v jednom tabletu
                            </div>
                          </li>
                        </ul>
                        <div className="pricing-tag pricing-tag-top">
                          doporučujeme
                        </div>
                        <Link
                          to={"/produkty#online-objednavky"}
                          className="button-outlined button-outlined-white w-button"
                        >
                          Více zde
                        </Link>
                        <Link
                          to="/produkty#calculator"
                          className="pricing-tag pricing-tag-bottom"
                        >
                          <img
                            src="images/settings.svg"
                            loading="lazy"
                            alt="kalkulator ceny"
                            title="kalkulačka ceny"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="pricing-slider-slide-container w-slide">
                      <div className="pricing-card pricing-card-3">
                        <img
                          src="images/dispecink_beta.svg"
                          loading="lazy"
                          alt="dispečink pro rozvoz objednávek"
                          title="Dispečink"
                          className="pricing-image pricing-image-dispatching"
                        />
                        <h3 className="pricing-title">Dispečink</h3>
                        <div className="pricing-subtitle">cena od</div>
                        <div className="pricing-price">
                          <div className="pricing-price-value">1 500</div>
                          <div className="pricing-price-unit">Kč/měsíc</div>
                        </div>
                        <ul
                          role="list"
                          className="pricing-feature-list w-list-unstyled"
                        >
                          <li>
                            <div className="pricing-feature">
                              vlastní rozvoz
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature">kniha jízd</div>
                          </li>
                          <li>
                            <div className="pricing-feature">
                              aplikace pro kurýry
                            </div>
                          </li>
                        </ul>
                        <Link
                          to={"/produkty#dispecink"}
                          className="button-outlined w-button"
                        >
                          Více zde
                        </Link>
                      </div>
                    </div>
                    <div className="pricing-slider-slide-container w-slide">
                      <div className="pricing-card pricing-card-4">
                        <img
                          src="images/web.svg"
                          loading="lazy"
                          alt="vlastní eshop pro restauraci"
                          title="Vlastní eshop"
                          className="pricing-image pricing-image-web"
                        />
                        <h3 className="pricing-title">Web</h3>
                        <div className="pricing-subtitle">cena od</div>
                        <div className="pricing-price">
                          <div className="pricing-price-value">900</div>
                          <div className="pricing-price-unit">Kč/měsíc</div>
                        </div>
                        <ul
                          role="list"
                          className="pricing-feature-list w-list-unstyled"
                        >
                          <li>
                            <div className="pricing-feature">
                              vlastní web na míru
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature">
                              nahrávání menu
                            </div>
                          </li>
                          <li>
                            <div className="pricing-feature">
                              ovládání přes tablet
                            </div>
                          </li>
                        </ul>
                        <Link
                          to={"/produkty#vlastni-eshop"}
                          className="button-outlined w-button"
                        >
                          Více zde
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-slider-arrow pricing-slider-arrow-left w-slider-arrow-left">
                    <div className="pricing-slider-arrow-icon w-icon-slider-left" />
                  </div>
                  <div className="pricing-slider-arrow pricing-slider-arrow-right w-slider-arrow-right">
                    <div className="pricing-slider-arrow-icon w-icon-slider-right" />
                  </div>
                  <div className="pricing-slider-nav w-slider-nav w-round" />
                </div>
              </div>
            </section>
            <section id="calculator" className="calculator-section wf-section">
              <div className="container">
                <div className="heading-wrapper">
                  <h2 className="heading-2">Najdeme pro vás řešení</h2>
                  <p className="paragraph">
                    Zodpovězte nám prosím několik otázek v pár jednoduchých
                    krocích, a my se Vám pokusíme vyskládat cenu na míru.
                  </p>
                </div>
                <Calculator />
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <section className="logos-section logos-section-clients wf-section">
              <div className="container">
                <div className="heading-wrapper">
                  <h2 className="heading-2">Důvěřují nám</h2>
                  <p className="paragraph">
                    Pokud chcete ušetřit čas, peníze a zefektivnit Váš provoz,
                    přidejte se k těm nejchytřejším restauracím na trhu.
                  </p>
                  <div
                    data-delay={4000}
                    data-animation="slide"
                    className="slider w-slider"
                    data-autoplay="true"
                    data-easing="ease"
                    data-hide-arrows="false"
                    data-disable-swipe="true"
                    data-autoplay-limit={0}
                    data-nav-spacing={5}
                    data-duration={500}
                    data-infinite="false"
                  >
                    <div className="mask w-slider-mask">
                      <div className="slide-container w-slide">
                        <div className="slide">
                          <img
                            sizes="(max-width: 479px) 390.34375px, (max-width: 767px) 420px, (max-width: 991px) 90vw, 730px"
                            srcSet="images/pozadiYamyam.webp 500w, images/pozadiYamyam.webp 553w"
                            loading="lazy"
                            src="images/pozadiYamyam.webp"
                            alt="Yam Yam restaurace"
                            title="Yam Yam "
                            className="slide-image"
                          />
                          <div className="carousel-item-logo-container">
                            <img
                              loading="lazy"
                              src="images/logoyam.webp"
                              alt="Yam Yam logo"
                              title="Yam Yam"
                              className="carousel-item-logo"
                            />
                          </div>
                          <div className="carousel-item-text-wrapper">
                            <div>
                              {" "}
                              Restii a dispečink používáme déle než dva roky a
                              jsme velmi spokojeni. Všechny interní procesy a
                              postupy se zefektivnily a tím jsme ušetřili dost
                              peněz. <p></p>
                              Jakub Zajíček, Yam Yam
                              <br />‍
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slide-container w-slide">
                        <div className="slide">
                          <img
                            sizes="(max-width: 479px) 190.109375px, (max-width: 767px) 420px, (max-width: 991px) 90vw, 730px"
                            srcSet="images/backgroundFary.webp 500w, images/backgroundFary.webp 789w"
                            loading="lazy"
                            src="farypizza"
                            alt="farypizza restaurace"
                            title="farypizza logo"
                            className="slide-image"
                          />
                          <div className="carousel-item-logo-container">
                            <img
                              loading="lazy"
                              src="images/logoFary.webp"
                              alt="farypizza logo"
                              title="Farypizza"
                              className="carousel-item-logo"
                            />
                          </div>
                          <div className="carousel-item-text-wrapper">
                            <div>
                              {" "}
                              Díky RESTII rosteme a jsme schopni rozvézt až 50
                              objednávek za hodinu. To bychom bez tohoto systému
                              nezvládli. Investice se nám již několikanásobně
                              vrátila. Oceňuji okamžitý přehled o tržbách,
                              zákaznících i personálu. <p></p>
                              Pavel Farský, Farypizza
                              <br />‍
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slide-container w-slide">
                        <div className="slide">
                          <img
                            loading="lazy"
                            src="images/paprika.webp"
                            alt="PAPRIKA Mediterranean Kitchen & Bar restaurace"
                            title="PAPRIKA Mediterranean Kitchen & Bar"
                            className="slide-image"
                          />
                          <div className="carousel-item-logo-container">
                            <img
                              loading="lazy"
                              src="images/paprika_logo.webp"
                              alt="PAPRIKA Mediterranean Kitchen & Bar logo"
                              title="PAPRIKA Mediterranean Kitchen & Bar"
                              className="carousel-item-logo"
                            />
                          </div>
                          <div className="carousel-item-text-wrapper">
                            <div>
                              {" "}
                              S propojením od RESTIA nám odpadá markování online
                              objednávek a celý systém se tím velmi zrychlil.
                              Zákazníci rádi využívají i moderní eshop, který
                              nám dodali na klíč. Rozhodně bychom neměnili a
                              můžeme jen doporučit. <p></p>Gal Levi, Paprika
                              <br />‍
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slide-container w-slide">
                        <div className="slide">
                          <img
                            loading="lazy"
                            src="images/smichovna.webp"
                            alt="smíchovna restaurace"
                            title="Smíchovna restaurace"
                            className="slide-image"
                          />
                          <div className="carousel-item-logo-container">
                            <img
                              loading="lazy"
                              src="images/smichovna_logo.webp"
                              alt="smíchovna logo"
                              title="Smíchovna"
                              className="carousel-item-logo"
                            />
                          </div>
                          <div className="carousel-item-text-wrapper">
                            <div>
                              {" "}
                              Od doby, co jsme začali spolupracovat s RESTIÍ,
                              sledujeme nejen navýšení počtu objednávek a jejich
                              rychlejší vyřízení, ale i daleko jednodušší správu
                              naší online nabídky. Ušetřený čas tak můžeme
                              věnovat tam, kde je to zrovna potřeba. <p></p>
                              František Hrdlička, Smíchovna
                              <br />‍
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slide-container w-slide">
                        <div className="slide">
                          <img
                            loading="lazy"
                            src="images/corleone.webp"
                            alt="corleone restaurace"
                            title="Corleone"
                            className="slide-image"
                          />
                          <div className="carousel-item-logo-container">
                            <img
                              loading="lazy"
                              src="images/corleone_logo.webp"
                              alt="corleone logo"
                              title="Corleone"
                              className="carousel-item-logo"
                            />
                          </div>
                          <div className="carousel-item-text-wrapper">
                            <div>
                              {" "}
                              Teplá pizza, kterou rychle doručíte, je ideálním
                              způsobem pro získání nového zákazníka. A těch máme
                              od začátku spolupráce s RESTIA stále víc a víc bez
                              nutnosti nabírání nových zaměstnanců. Je to
                              elegantní řešení, které rozhodně zvyšuje naši
                              konkurenceschopnost. <p></p>Jaroslav Kolanda,
                              Corleone <br />‍
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="left-arrow w-slider-arrow-left">
                      <div className="w-icon-slider-left" />
                    </div>
                    <div className="right-arrow w-slider-arrow-right">
                      <div className="w-icon-slider-right" />
                    </div>
                    <div className="slide-nav w-slider-nav w-round" />
                  </div>
                </div>
              </div>
            </section>
            <section className="logos-section logos-section-pos wf-section">
              <div className="container">
                <div className="heading-wrapper">
                  <h2 className="heading-2">Pokladní systémy</h2>
                  <p className="paragraph">
                    Máme přímé napojení do několika pokladních systémů a každý
                    den přidáváme a napojujeme další. Pokud zde Váš POS systém
                    není uveden, kontaktuje nás, rádi navrhneme optimální
                    řešení, případně sdělíme termín napojení.
                  </p>
                </div>
                <div className="w-layout-grid companies-grid companies-grid-small">
                  <a href="https://dotykacka.cz/">
                    <img
                      src="images/dotykacka.webp"
                      loading="lazy"
                      id="w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b4738-ff0fd418"
                      alt="dotykačka logo"
                      title="Dotykačka"
                      className="company-logo"
                    />
                  </a>
                  <a href="http://www.mefisto.cz/">
                    <img
                      src="images/mefisto.webp"
                      loading="lazy"
                      id="w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b4739-ff0fd418"
                      alt="mefisto logo"
                      title="Mefisto"
                      className="company-logo"
                    />
                  </a>
                  <a href="https://storyous.com/">
                    <img
                      src="images/storyous_pos.webp"
                      loading="lazy"
                      id="w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b473a-ff0fd418"
                      alt="storyous logo"
                      title="Storyous"
                      className="company-logo"
                    />
                  </a>
                  <a href="https://rkeeper.cz/">
                    <img
                      src="images/rkeeper.webp"
                      loading="lazy"
                      id="w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b473b-ff0fd418"
                      alt="rkeeper logo"
                      title="Rkeeper"
                      className="company-logo"
                    />
                  </a>
                </div>
              </div>
            </section>
            <div className="divider-container">
              <div className="divider" />
            </div>
            <ContactForm />
            <Footer />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default IndexView;

/* eslint-enable */
